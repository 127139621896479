.label-overlay-active{
  .leaflet-control-container .leaflet-top.leaflet-left{
    .leaflet-control-mapbox-geocoder,.leaflet-draw.leaflet-control{
      a{
        position: relative;
        display: block;
        opacity: 1 !important;
        &.leaflet-control-mapbox-geocoder-toggle{
          &:before{
            content: "Search places";
          }
        }
        &.leaflet-draw-draw-polyline{
          &:before{
            content: "Draw a path";
          }
        }
        &.leaflet-draw-edit-edit{
          &:before{
            content: "Edit layers";
          }
        }
        &.leaflet-draw-edit-remove{
          &:before{
            content: "Delete layers";
          }
        }
        &:before{
          content: attr(title);
          background: rgba($secondary_color, 0.95);
          color: #fff;
          position: absolute;
          left: 100%;
          top: 50%;
          transform: translate(0, -50%);
          font-size: 16px;
          font-weight: bold;
          white-space: nowrap;
          margin-left: 20px;
          padding: 4px 10px;
          border-radius: $default_radius;
        }
        &:after{
          font-family: "Font Awesome 5 Pro";
          content: "\f060";
          position: absolute;
          font-size: 20px;
          left: 100%;
          top: 50%;
          margin-left: 5px;
          transform: translate(0, -50%);
          color: $secondary_color;
        }
      }
    }
    .leaflet-draw-toolbar{
      a{
        margin-top: 5px !important;
        margin-bottom: 5px !important;
      }
    }
  }
  .leaflet-container{
    pointer-events: none;
    &:after{
      content: "";
      z-index: 10;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none !important;
      background: rgba(#000,0.7);
    }
    .leaflet-pane.leaflet-map-pane{
      opacity: 0.3;
    }
  }
}
