.popover{
  border-radius: 4px;
  background: $dark_color;
  color: #FFFFFF;
  opacity: 0;
  visibility: hidden;
  transition: $default_transition;
  .popover-header {
    background-color: rgba(#fff, 0.1);
    color: #FFFFFF;
    padding: 5px 10px;
    border: none;
  }
  .popover-body {
    color: #FFFFFF;
    border-radius: 4px;
    padding: 15px;
    ul{
      padding-left: 15px;
      &.error-dependant-list{
        margin: 0;
        li{
          display: none;
        }
      }
    }

  }
  &.bs-popover-top{
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 100%;
    top: auto;
    max-width: 100%;
  }
  .progress{
    background: #444;
    height: calc(0.1vh + 10px);
    margin-top: 15px;
    .progress-bar{
      background: $primary_color;
    }
  }
}
.bs-popover-auto[x-placement^="top"] > .arrow::after, .bs-popover-top > .arrow::after{
  border-top-color: $dark_color;
}
