.alert-modal{
  position: fixed;
  left: 0;
  top:0;
  z-index: 9999;
  background: rgba(#000,0.8);
  width: 100%;
  height: 100%;
  &-inner{
    text-align: center;
    background: #fff;
    border-radius: 10px;
    width: calc(200px + 10vw);
    height: auto;
    padding: calc(10px + 2vw + 1vh);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    h1,h2,h3,h4,h5,h6{
      margin: calc(0.3vh + 0.3vw + 10px) 0;
    }
  }
  &.success{
    h1,h2,h3,h4,h5,h6{
      color: $success;
    }
  }
  &.warning{
    h1,h2,h3,h4,h5,h6{
      color: $warning;
    }
  }
  &.danger{
    h1,h2,h3,h4,h5,h6{
      color: $danger;
    }
  }
}