.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid rgba(#000, 0.1);
  background: $light_color;
  border-radius: $default_radius;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: none;
  border-radius: 2px;
  background-color: $primary_color;
  color: #fff;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }

}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
  background: none;
  margin-left: 10px;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 999;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 240px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.tags-all-list{
  box-shadow: 0 0 10px 2px rgba(#000, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in;
  &.active{
    opacity: 1;
    visibility: visible;
  }
  .close-tag-list{
    position: absolute;
    right: 0;
    top: 0;
    width: 35px;
    height: 35px;
    background: none;
    border: none;
  }
  header{
    position: absolute;
    top: 10px;
    left: 20px;
    font-weight: 600;
  }
  &-inner{
    background: #fff;
    padding: 50px 20px 20px;
    height: 100%;
  }
  ul{
    margin: 0;
    padding: 0;
    font-weight: 400;
    li{
      display: inline-block;
      button{
        font-weight: 400;
        background: $light_color;
        margin: 4px;
        padding: 5px 10px;
        @include font-size(1);
      }
    }
  }
}
